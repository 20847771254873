<div class="modal-body submit-proof">
  <span class="dialog-close" (click)="onClose()">X</span>
  <form [formGroup]="submitProofForm" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-12 mb-3">
        <h1 class="title">Please select the activity you have completed. 
          If you have a Nutanix BDF number you do not need to upload a file. 
          If you do not have a BDF please upload the required evidence. </h1>
      </div>
      <div class="col-md-8 col-sm-6">
        <div class="form-group mb-3">
          <label>{{ 'Select Your Activity' | translate }}</label>
          <select formControlName="sprintActivityId" class="form-control">
            <option hidden [value]="null">Select</option>
            <option *ngFor="let activity of activities$ | async" [value]="activity.id">{{ activity.name }}</option>
          </select>
          <span class="error" *ngIf="submitted && submitProofForm.controls['sprintActivityId'].invalid"
            >Activity is required</span
          >
        </div>
        <div
          class="form-group mb-3"
          *ngIf="showAllFields || resellerNameRequired || !submitProofForm.value?.sprintActivityId"
        >
          <label>{{ 'Reseller Name' | translate }}</label>
          <input type="text" class="form-control" formControlName="resellerName" />
          <span class="error" *ngIf="submitted && submitProofForm.controls['resellerName'].invalid"
            >Reseller name is required</span
          >
        </div>
        <div class="form-group mb-3" *ngIf="showAllFields || dateRequired || !submitProofForm.value?.sprintActivityId">
          <label>{{ 'Date of activity' | translate }}</label>
          <input type="date" class="form-control" formControlName="activityDate" />
          <span class="error" *ngIf="submitted && submitProofForm.controls['activityDate'].invalid"
            >Date is required</span
          >
        </div>
        <div
          class="form-group mb-3"
          *ngIf="showAllFields || BDFNumberRequired || !submitProofForm.value?.sprintActivityId"
        >
          <label>{{ 'BDF Number' | translate }}</label>
          <input type="text" class="form-control" formControlName="BDFNumber" />
          <span class="error" *ngIf="submitted && submitProofForm.controls['BDFNumber'].invalid"
            >BDF number is required</span
          >
        </div>
      </div>
      <div class="col-md-4 col-sm-6 d-flex flex-column justify-content-end">
        <label>{{ 'Upload supporting proof' | translate }}</label>
        <div class="upload-wrapper d-flex flex-column justify-content-center mb-3">
          <div class="custom-file-upload d-flex align-items-center justify-content-center" (click)="inputFile.click()">
            <input type="file" (change)="handleFileUpload($event)" #inputFile />
            <span class="btn-file">Browse Files</span>
          </div>
          <span class="filename mt-2">{{ currentFile?.name }}</span>
          <span class="error" *ngIf="submitted && submitProofForm.controls['fileUrl'].invalid">Upload is required</span>
        </div>
        <button type="submit" class="btn btn-primary btn-submit">{{ 'Submit proof documents' | translate }}</button>
      </div>
      <div class="col-12 mt-5 mb-3">
        <p>Need help? Contact us: <a href="mailto:service@racetotheolympics.com">service@racetotheolympics.com</a></p>
      </div>
    </div>
  </form>
</div>
