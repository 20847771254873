import { Component, OnInit } from '@angular/core';
import { AbstractDialogComponent, DialogNgbService } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SprintActivity } from 'src/app/core/model/core/sprint-activity';

@Component({
  selector: 'app-sprint-dialog',
  templateUrl: './sprint-dialog.component.html',
  styleUrls: ['./sprint-dialog.component.scss'],
})
export class SprintDialogComponent extends AbstractDialogComponent implements OnInit {
  sprintActivity: SprintActivity;

  constructor(private dialogService: DialogNgbService, public activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs && this.inputs.length > 0) {
      this.sprintActivity = this.inputs[0];
    }
  }

  onOpenSubmitProofDialog(sprintActivity: SprintActivity): void {
    if (sprintActivity) {
      this.dialogService
        .openFeatureDialog('submitProofDialog', [sprintActivity, false], {
          size: 'lg',
          windowClass: 'submit-proof-dialog',
        })
        .then((closeAll) => {
          if (closeAll) {
            this.onClose();
          }
        });
    }
  }

  onClose(): void {
    this.activeModal.close();
  }
}
