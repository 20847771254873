<app-public-main-layout [background]="background">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="formGroup">
        <div class="row login-form">
          <div class="mb-3 col-12 d-flex flex-column align-items-start">
            <label>Email</label>
            <input class="form-control" type="email" formControlName="email" autocomplete="off" />
          </div>
          <div class="mb-3 col-12 d-flex flex-column align-items-start">
            <label>Password</label>
            <input class="form-control" type="password" formControlName="password" autocomplete="off" />
          </div>
        </div>
      </form>
      <div class="mt-3 d-flex flex-column align-items-center">
        <button type="submit" class="btn btn-primary btn-round btn-register" (click)="onSubmit()">Login</button>
        <a class="mt-3" routerLink="/auth/forgot-password">Forgot Password</a>
      </div>
    </div>
  </div>
</app-public-main-layout>
