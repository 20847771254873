<footer class="footer">
  <div class="d-flex justify-content-center">
    <a href="https://www.nutanix.com/legal/privacy-statement" target="_blank" class="px-4">Nutanix Privacy Statement</a>
    <!--
    <a class="px-4" href="#">FAQ</a>
    -->
    <a
      class="px-4"
      href="https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/T%26C-Nutanix_FY23_Race_to_Olympics_v1_16.06.23+.docx.pdf"
      target="_blank"
      >T&C's</a
    >
    <a class="px-4" href="mailto:support@racetotheolympics.com" target="_blank">Contact Us</a>
  </div>
</footer>
