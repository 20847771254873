import { Injectable } from '@angular/core';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';

import { ActivityRestService } from '../api/rest/activity-rest.service';
import { Activity, ActivityRequirement } from '../model/core/activity';
import { SubmitProof } from '../model/core/submit-proof';

@Injectable({
  providedIn: 'root',
})
export class ActivityStoreService {
  private readonly _activities = new BehaviorSubject<Activity[] | null>(null);
  readonly activities$ = this._activities.asObservable();

  private readonly _activityRequirements = new BehaviorSubject<ActivityRequirement[] | null>(null);
  readonly activityRequirements$ = this._activityRequirements.asObservable();

  private readonly _submitProofThankYouMessage = new BehaviorSubject<string | null>(null);
  readonly submitProofThankYouMessage$ = this._submitProofThankYouMessage.asObservable();

  constructor(private activityRest: ActivityRestService, private isLoadingService: IsLoadingService) {}

  get activities(): Activity[] | null {
    return this._activities.getValue();
  }

  get activityRequirements(): ActivityRequirement[] | null {
    return this._activityRequirements.getValue();
  }

  getActivities(): void {
    if (this.activities) return;
    this.isLoadingService.add(
      this.activityRest.getActivities().subscribe((activities) => {
        this._activities.next(activities);
      })
    );
  }

  submitProof(submitProof: SubmitProof): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.activityRest.submitProof(submitProof).subscribe(
          () => resolve(true),
          () => resolve(false)
        )
      );
    });
  }

  uploadProofFile(sprintActivityId: number, file: File): Promise<string | null> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.activityRest
          .getPresignedUrlFile({ sprintActivityId, fileName: file.name, fileContentType: file.type })
          .subscribe({
            next: (presignedUrl) =>
              this.isLoadingService.add(
                this.activityRest.uploadProofFile(file, presignedUrl.url).subscribe({
                  next: () => {
                    resolve(presignedUrl.fileUrl);
                  },
                  error: () => {
                    resolve(null);
                  },
                })
              ),
            error: () => {
              resolve(null);
            },
          })
      );
    });
  }

  setActivityRequirements(activityRequirements: ActivityRequirement[]): void {
    this._activityRequirements.next(activityRequirements);
  }

  setSubmitProofThankYouMessage(message: string): void {
    this._submitProofThankYouMessage.next(message);
  }
}
