import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-public-main-layout',
  templateUrl: './public-main-layout.component.html',
  styleUrls: ['./public-main-layout.component.scss'],
})
export class PublicMainLayoutComponent {
  @Input() background: string;
}
