import { Component, Input } from '@angular/core';
import { Article } from '@motivforce/mx-library-angular';

@Component({
  selector: 'app-promotion-box',
  templateUrl: './promotion-box.component.html',
  styleUrls: ['./promotion-box.component.scss'],
})
export class PromotionBoxComponent {
  @Input() promotion: Article | null;
  @Input() hasThumbnailText = false;
  @Input() hasLargeTitle = false;
}
