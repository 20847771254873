<div class="modal-body spin-wheel-prize-dialog" [style.background-image]="'url(' + prizeBackground + ')'">
  <span class="dialog-close" (click)="onClose()">X</span>

  <div class="row">
    <div class="col-12 mb-3">
      <h1 class="title" [innerHTML]="prizeMessage"></h1>
      <div *ngIf="prizeImage !== null">
        <p>{{ 'Your prize' | translate }}</p>
        <img [src]="prizeImage" [alt]="prizeMessage" />
        <p>
          {{ "You don't need to do anything - we'll be in touch with details of how to claim your prize." | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
