import { Injectable } from '@angular/core';
import {
  AccountSummary,
  ApiRestService,
  RedeemedReward,
  ReferenceData,
  SearchResult,
} from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
// import { RedeemedReward } from 'src/app/product-catalogue/model/redeemed-reward';
import { environment } from 'src/environments/environment';

import { Booking } from '../../model/booking';
import { BookingSearch } from '../../model/booking-search';
import { CommunicationsPreference } from '../../model/core/communications-preference';
import { Profile } from '../../model/core/profile';
import { RedemptionSearch } from '../../model/core/redemption-search';
import { SetPassword } from '../../model/core/set-password';
import { Transaction } from '../../model/core/transaction';
import { TransactionSearch } from '../../model/core/transaction-search';

@Injectable({
  providedIn: 'root',
})
export class UserRestService {
  constructor(private apiRest: ApiRestService) {}

  getAccountSummary(): Observable<AccountSummary> {
    return this.apiRest.get<AccountSummary>(`${environment.api.core.baseUrl}/members/users/account-summary`);
  }

  getYearAccountSummaries(): Observable<AccountSummary[]> {
    return this.apiRest.get<AccountSummary[]>(`${environment.api.core.baseUrl}/members/users/points/year-summaries`);
  }

  getProfile(): Observable<Profile> {
    return this.apiRest.get<Profile>(`${environment.api.core.baseUrl}/members/clients/amber/profile`);
  }

  updateProfile(profile: Profile): Observable<any> {
    return this.apiRest.put<any>(`${environment.api.core.baseUrl}/members/clients/amber/profile`, profile);
  }

  searchTransactions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/transactions/search`,
      searchCriteria
    );
  }

  getTransactionTypes(): Observable<ReferenceData[]> {
    return this.apiRest.get<ReferenceData[]>(`${environment.api.core.baseUrl}/members/transactions/types`);
  }

  searchPromotions(searchCriteria: TransactionSearch): Observable<SearchResult<Transaction>> {
    return this.apiRest.post<SearchResult<Transaction>>(
      `${environment.api.core.baseUrl}/members/users/promotion-transactions`,
      searchCriteria
    );
  }

  searchRedeemedRewards(searchCriteria: RedemptionSearch): Observable<SearchResult<RedeemedReward>> {
    return this.apiRest.post<SearchResult<RedeemedReward>>(
      `${environment.api.core.baseUrl}/members/redemptions/search`,
      searchCriteria
    );
  }

  searchBookings(searchCriteria: BookingSearch): Observable<SearchResult<Booking>> {
    return this.apiRest.post<SearchResult<Booking>>(
      `${environment.api.core.baseUrl}/members/clients/amber/bookings/search`,
      searchCriteria
    );
  }

  getCommunicationPreferences(): Observable<any> {
    return this.apiRest.get(
      `${environment.api.core.baseUrl}/members/clients/nutanix/profile/communications-preferences`
    );
  }

  updateCommunicationPreferences(form: CommunicationsPreference): Observable<any> {
    return this.apiRest.post(
      `${environment.api.core.baseUrl}/members/clients/nutanix/profile/communications-preferences`,
      form
    );
  }

  acceptTermsConditions(): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/terms-and-conditions/accept`, null);
  }

  searchAvailableVenues(name: string): Observable<ReferenceData[]> {
    return this.apiRest.post<ReferenceData[]>(
      `${environment.api.core.baseUrl}/members/clients/amber/venues/search-available`,
      { name }
    );
  }

  updateVenue(venueId: number): Observable<void> {
    return this.apiRest.put<void>(`${environment.api.core.baseUrl}/members/clients/amber/userdetails/venue`, {
      venueId,
    });
  }

  setPassword(password: SetPassword) {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/users/set-password`, password);
  }

  createCheckoutSession(form: any): Observable<any> {
    return this.apiRest.post(`${environment.api.core.baseUrl}/members/payments/create-checkout-session`, form);
  }
}
