<div class="container-fluid background" [style.background-image]="'url(' + background + ')'">
  <div class="row">
    <div class="col-12 mt-5 px-5 d-flex flex-column justify-content-center align-items-center">
      <img class="logo" src="https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Nutanix-logo.png" />
      <img
        class="rtto"
        src="https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/race-to-olympics-logo.png"
      />
    </div>
    <ng-content></ng-content>
  </div>
</div>
