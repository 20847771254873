import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { DialogNgbService, QuizModule } from '@motivforce/mx-library-angular';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';

import { DialogComponent } from './components/dialog/dialog.component';
import { PromotionPowDialogComponent } from './components/promotion-pow-dialog/promotion-pow-dialog.component';
import { PromotionQowDialogComponent } from './components/promotion-qow-dialog/promotion-qow-dialog.component';
import { SpinWheelPrizeDialogComponent } from './components/spin-wheel-prize-dialog/spin-wheel-prize-dialog.component';
import { SprintDialogComponent } from './components/sprint-dialog/sprint-dialog.component';
import { SubmitProofDialogComponent } from './components/submit-proof-dialog/submit-proof-dialog.component';
import { SubmitProofThankYouDialogComponent } from './components/submit-proof-thank-you-dialog/submit-proof-thank-you-dialog.component';
import { TermsConditionsAcceptDialogComponent } from './components/terms-conditions-accept-dialog/terms-conditions-accept-dialog.component';

@NgModule({
  declarations: [
    DialogComponent,
    PromotionQowDialogComponent,
    TermsConditionsAcceptDialogComponent,
    PromotionPowDialogComponent,
    SprintDialogComponent,
    SubmitProofDialogComponent,
    SpinWheelPrizeDialogComponent,
    SubmitProofThankYouDialogComponent,
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    RouterModule,
    QuizModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule,
  ],
  exports: [NgbModalModule],
})
export class DialogModule {
  constructor(private dialog: DialogNgbService, private iconlibrary: FaIconLibrary) {
    this.dialog.registerNotificationDialog(DialogComponent);
    this.dialog.registerErrorDialog(DialogComponent);
    this.dialog.registerWarningDialog(DialogComponent);
    this.dialog.registerValidationDialog(DialogComponent);

    this.dialog.registerFeatureDialog('promotionQowDialog', PromotionQowDialogComponent);
    this.dialog.registerFeatureDialog('termsConditionsAcceptDialog', TermsConditionsAcceptDialogComponent);
    this.dialog.registerFeatureDialog('promotionPowDialog', PromotionPowDialogComponent);
    this.dialog.registerFeatureDialog('sprintDialog', SprintDialogComponent);
    this.dialog.registerFeatureDialog('submitProofDialog', SubmitProofDialogComponent);
    this.dialog.registerFeatureDialog('spinWheelPrizeDialog', SpinWheelPrizeDialogComponent);
    this.dialog.registerFeatureDialog('submitProofThankYouDialog', SubmitProofThankYouDialogComponent);

    this.iconlibrary.addIcons(faClock);
  }
}
