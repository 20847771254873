<div class="modal-body sprint-activity">
  <span class="dialog-close" (click)="onClose()">X</span>
  <div *ngIf="sprintActivity" [innerHTML]="sprintActivity.content"></div>
  <button
    class="btn btn-primary btn-submit"
    (click)="onOpenSubmitProofDialog(sprintActivity)"
    *ngIf="sprintActivity.sprintActivityId"
  >
    {{ 'Submit proof' | translate }}
  </button>
</div>
