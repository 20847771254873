import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, TranslateResolver } from '@motivforce/mx-library-angular';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    loadChildren: () => import('./landing/landing.module').then((m) => m.LandingModule),
    canActivate: [],
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [],
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule),
    canActivate: [],
  },
  {
    path: 'thank-you',
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'sprints',
    loadChildren: () => import('./sprints/sprints.module').then((m) => m.SprintsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'leaderboard',
    loadChildren: () => import('./leaderboard/leaderboard.module').then((m) => m.LeaderboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'terms-and-conditions',
    data: { breadcrumbLabel: (data: any) => data.translate.instant('Terms and conditions') },
    resolve: { translate: TranslateResolver },
    loadChildren: () => import('@motivforce/mx-library-angular').then((m) => m.ContentModule),
    canActivate: [],
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then((m) => m.SupportModule),
    canActivate: [],
  },
  {
    path: 'spin-to-win',
    loadChildren: () => import('./spin-to-win/spin-to-win.module').then((m) => m.SpinToWinModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'prizes',
    loadChildren: () => import('./prizes/prizes.module').then((m) => m.PrizesModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'landing',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
