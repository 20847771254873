<app-modal-open-padding-wrapper>
  <header #header [ngClass]="{ fixed: headerFixed }">
    <div class="navbar" *ngIf="(authenticatedUser$ | async) !== null">
      <div class="h-100 container-fluid">
        <div class="m-0 w-100 h-100 row">
          <div class="col-md-3 d-flex align-items-center">
            <a routerLink="/home">
              <img
                class="logo ms-5"
                src="https://mmicdn.s3.eu-central-1.amazonaws.com/NutanixRaceToOlympics/Nutanix-logo.png"
              />
            </a>
          </div>
          <div class="p-0 col-md-9 ">
            <div class="h-100 d-none d-lg-block navbar-wrapper" *ngIf="headerMenu">
              <div class="h-100 lower-nav mx-5 px-lg-5 px-xxl-0 d-flex justify-content-end align-items-right">
                <ng-container *ngFor="let item of headerMenu.items">
                  <ng-container *ngIf="item.items && item.items.length === 0">
                    <div
                      class="h-100 d-flex justify-content-center align-items-center navbar-item me-5"
                      routerLinkActive="active"
                    >
                      <a [routerLink]="item.url" *ngIf="!item.url.includes('http')">
                        <span>{{ item.label }}</span>
                      </a>
                      <a [href]="item.url" target="_blank" *ngIf="item.url.includes('http')">
                        <span>{{ item.label }}</span>
                      </a>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="item.items && item.items.length > 0">
                    <div class="h-100 navbar-item dropdown my-accounts">
                      <a
                        [routerLink]="item.url"
                        routerLinkActive="active"
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        *ngIf="!item.url.includes('http')"
                        >{{ item.label }}</a
                      >
                      <a
                        [href]="item.url"
                        target="_blank"
                        class="dropdown-toggle"
                        data-toggle="dropdown"
                        *ngIf="item.url.includes('http')"
                        >{{ item.label }}</a
                      >
                      <ul class="dropdown-menu">
                        <ng-container *ngFor="let subItem of item.items">
                          <li>
                            <a class="dropdown-item" [routerLink]="subItem.url" *ngIf="!subItem.url.includes('http')">{{
                              subItem.label
                            }}</a>
                            <a
                              class="dropdown-item"
                              [href]="subItem.url"
                              target="_blank"
                              *ngIf="subItem.url.includes('http')"
                              >{{ subItem.label }}</a
                            >
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div class="d-lg-none w-100 h-100 navbar-wrapper flex-row justify-content-between align-items-center">
              <app-main-menu [headerMenu]="headerMenu"></app-main-menu>
              <div class="d-flex justify-content-end align-items-center icons-nav">
                <div class="pb-2">
                  <lib-switch-back-to-admin-button></lib-switch-back-to-admin-button>
                </div>
                <app-profile-menu class="ms-3"></app-profile-menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="me-3 me-xxl-4 d-none d-lg-flex justify-content-end align-items-center icons-nav absolute">
        <div class="pb-2">
          <lib-switch-back-to-admin-button></lib-switch-back-to-admin-button>
        </div>
        <app-profile-menu class="ms-2 ms-xxl-3"></app-profile-menu>
      </div>
    </div>
  </header>
</app-modal-open-padding-wrapper>
