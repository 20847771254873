<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ 'Success' | translate }}</h4>
</div>
<div class="modal-body">
  <div
    *ngIf="activityStore.submitProofThankYouMessage$ | async as message"
    [innerHTML]="message || defaultMessage"
  ></div>
</div>
<div class="modal-footer">
  <button class="btn btn-outline-primary" type="submit" (click)="activeModal.close()">
    {{ 'Close' | translate }}
  </button>
</div>
