import { Component, OnInit } from '@angular/core';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-spin-wheel-prize-dialog',
  templateUrl: './spin-wheel-prize-dialog.component.html',
  styleUrls: ['./spin-wheel-prize-dialog.component.scss'],
})
export class SpinWheelPrizeDialogComponent extends AbstractDialogComponent implements OnInit {
  prizeMessage: string;
  prizeImage: string;
  prizeBackground: string;

  constructor(public activeModal: NgbActiveModal) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs && this.inputs.length > 0) {
      this.prizeMessage = this.inputs[0];
      this.prizeImage = this.inputs[1];
      this.prizeBackground = this.inputs[2];
    }
  }

  onClose(): void {
    this.activeModal.close();
    window.location.reload();
  }
}
