<app-public-main-layout [background]="background">
  <div class="row forgot-password-form">
    <div class="col-12">
      <h3 class="text-center mb-3">
        Enter your Email Address and then click <br class="d-lg-none" />the 'Get verification code' button.<br />
        You will receive an email with the code to reset your password.
      </h3>
      <div class="forgot-password-form-container">
        <form
          [formGroup]="formGroup"
          class="d-flex flex-column align-items-center justify-content-center w-100"
          (ngSubmit)="onSubmit()"
        >
          <div class="form-row p-1 w-50 d-none d-sm-block" *ngIf="!verificationSent">
            <input
              id="email"
              type="text"
              formControlName="email"
              placeholder="Email Address"
              class="w-100 form-control"
            />
          </div>
          <div class="form-row p-1 w-50 d-none d-sm-block" *ngIf="verificationSent">
            <input
              id="verificationCode"
              type="text"
              formControlName="verificationCode"
              placeholder="Verification code"
              class="w-100 form-control"
            />
          </div>
          <div class="form-row p-1 w-50 d-none d-sm-block" *ngIf="verificationSent">
            <input
              id="newPassword"
              type="password"
              formControlName="newPassword"
              placeholder="New password"
              class="w-100 form-control"
            />
            <p>
              The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, 1
              number and 1 special character from the following = + - ^ $ * . [ ] ( ) ? ! @ # % & / \ , > ' : ; | _ ~ `.
            </p>
          </div>
          <div class="form-row p-1 w-50 d-none d-sm-block" *ngIf="verificationSent">
            <input
              id="confirmedPassword"
              type="password"
              formControlName="confirmedPassword"
              placeholder="Confirm password"
              class="w-100 form-control"
            />
          </div>
          <div class="form-row w-75 pt-4 d-none d-sm-block">
            <div class="d-flex flex-column align-items-center justify-content-center w-100">
              <button class="btn btn-primary btn-round btn-forgot" type="submit">
                {{ verificationSent ? 'Reset Password' : 'Get Verification Code' }}
              </button>
              <a class="mt-3" routerLink="/auth/login">Back to Login</a>
            </div>
          </div>

          <div class="form-row p-1 w-100 d-sm-none" *ngIf="!verificationSent">
            <input
              id="email"
              type="text"
              formControlName="email"
              placeholder="Email Address"
              class="w-100 form-control"
            />
          </div>
          <div class="form-row p-1 w-100 d-sm-none" *ngIf="verificationSent">
            <input
              id="verificationCode"
              type="text"
              formControlName="verificationCode"
              placeholder="Verification code"
              class="w-100 form-control"
            />
          </div>
          <div class="form-row p-1 w-100 d-sm-none" *ngIf="verificationSent">
            <input
              id="newPassword"
              type="password"
              formControlName="newPassword"
              placeholder="New password"
              class="w-100 form-control"
            />
            <p>
              The password needs to be at least 8 characters long and contain 1 uppercase letter, 1 lowercase letter, 1
              number and 1 special character from the following = + - ^ $ * . [ ] ( ) ? ! @ # % & / \ , > ' : ; | _ ~ `.
            </p>
          </div>
          <div class="form-row p-1 w-100 d-sm-none" *ngIf="verificationSent">
            <input
              id="confirmedPassword"
              type="password"
              formControlName="confirmedPassword"
              placeholder="Confirm password"
              class="w-100 form-control"
            />
          </div>
          <div class="form-row pt-4 w-100 d-sm-none">
            <div class="d-flex flex-column align-items-center justify-content-center w-100">
              <button class="btn btn-primary btn-round btn-forgot" type="submit">
                {{ verificationSent ? 'Reset Password' : 'Get Verification Code' }}
              </button>
              <a class="mt-3" routerLink="/auth/login">Back to Login</a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</app-public-main-layout>
