import { Component, Input, OnInit } from '@angular/core';
import { Banner, ContentStoreService } from '@motivforce/mx-library-angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-default-main-layout',
  templateUrl: './default-main-layout.component.html',
  styleUrls: ['./default-main-layout.component.scss'],
})
export class DefaultMainLayoutComponent implements OnInit {
  @Input() background: string;
  @Input() banner: Banner | null;
  @Input() showSideWidgets = false;

  constructor(private contentStore: ContentStoreService) {}

  ngOnInit(): void {
    this.contentStore.banner$.pipe(untilDestroyed(this), filter(Boolean)).subscribe((banner) => {
      if (!this.banner) {
        this.banner = banner;
      }
    });
  }
}
