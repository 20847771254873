<!-- <app-box-hover-overlay [url]="promotion ? promotion.url : ''"> -->
<a [routerLink]="promotion ? promotion.url : ''">
  <img [src]="promotion?.imageUrl" />
  <div
    class="p-3 pb-2 d-flex flex-column justify-content-between box-text"
    [ngClass]="hasThumbnailText ? 'has-thumbnail-text' : ''"
    *ngIf="promotion?.title || promotion?.thumbnailText"
  >
    <h4 [innerHtml]="promotion?.title" [ngClass]="hasLargeTitle ? 'large-title' : ''"></h4>
    <div *ngIf="hasThumbnailText" [innerHTML]="promotion?.thumbnailText"></div>
    <!-- <div class="d-flex align-items-center">
      <div class="learn-more">{{ 'Learn more' | translate }}</div>
      <fa-icon class="ms-4" [icon]="['fal', 'arrow-right']" size="lg"></fa-icon>
    </div> -->
  </div>
</a>
