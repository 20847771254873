import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AbstractDialogComponent, AuthStoreService, DialogNgbService } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Activity } from 'src/app/core/model/core/activity';
import { ActivityStoreService } from 'src/app/core/store/activity-store.service';

@Component({
  selector: 'app-submit-proof-dialog',
  templateUrl: './submit-proof-dialog.component.html',
  styleUrls: ['./submit-proof-dialog.component.scss'],
})
export class SubmitProofDialogComponent extends AbstractDialogComponent implements OnInit {
  @ViewChild('inputFile') inputFile: ElementRef;
  activities$: Observable<Activity[] | null> = this.activityStore.activities$;
  user$ = this.authStore.userSettings$.pipe(map((settings) => (settings ? settings.user : null)));

  submitProofForm: UntypedFormGroup = this.formBuilder.group({
    sprintActivityId: [null],
    activityDate: [null],
    BDFNumber: [null],
    resellerName: [null],
    fileUrl: [null],
  });

  currentFile: File | null;
  submitted = false;
  showAllFields = false;
  BDFNumberRequired = false;
  dateRequired = false;
  resellerNameRequired = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private activityStore: ActivityStoreService,
    private authStore: AuthStoreService,
    private dialogService: DialogNgbService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.inputs && this.inputs.length > 0) {
      const sprintActivity = this.inputs[0];
      if (sprintActivity) {
        this.submitProofForm.get('sprintActivityId')?.patchValue(sprintActivity.sprintActivityId);
        this.setSubmitFormValidation(sprintActivity.sprintActivityId);
      }
    }
    if (this.inputs && this.inputs.length > 1) {
      this.showAllFields = this.inputs[1];
    }

    this.submitProofForm.get('sprintActivityId')?.valueChanges.subscribe((id) => {
      if (id && !this.showAllFields) {
        this.setSubmitFormValidation(id);

        // clear input file when activity dropdown is changed
        this.inputFile.nativeElement.value = null;
        this.submitProofForm.get('fileUrl')?.patchValue(null);
        this.currentFile = null;
      }
    });

    this.submitProofForm.get('fileUrl')?.valueChanges.subscribe((value) => {
      if (value && !this.showAllFields) {
        this.submitProofForm.get('BDFNumber')?.setValidators([]);
        this.submitProofForm.get('BDFNumber')?.updateValueAndValidity();
      }
    });

    this.submitProofForm.get('BDFNumber')?.valueChanges.subscribe((value) => {
      if (value && !this.showAllFields) {
        this.submitProofForm.get('fileUrl')?.setValidators([]);
        this.submitProofForm.get('fileUrl')?.updateValueAndValidity();
      } else if (!value && !this.showAllFields) {
        this.submitProofForm.get('fileUrl')?.setValidators([Validators.required]);
        this.submitProofForm.get('fileUrl')?.updateValueAndValidity();
      }
    });

    this.activityStore.getActivities();
  }

  setSubmitFormValidation(id: number): void {
    const requiredFields = this.activityStore.activityRequirements?.find(
      (activity) => activity.sprintActivityId === id
    );
    this.clearFormValidation();
    if (requiredFields) {
      if (requiredFields.BDFNumberRequired) {
        this.submitProofForm.get('BDFNumber')?.setValidators([Validators.required]);
        this.submitProofForm.get('BDFNumber')?.updateValueAndValidity();
        this.BDFNumberRequired = true;
      }
      if (requiredFields.dateRequired) {
        this.submitProofForm.get('activityDate')?.setValidators([Validators.required]);
        this.submitProofForm.get('activityDate')?.updateValueAndValidity();
        this.dateRequired = true;
      }
      if (requiredFields.uploadProofRequired) {
        this.submitProofForm.get('fileUrl')?.setValidators([Validators.required]);
        this.submitProofForm.get('fileUrl')?.updateValueAndValidity();
      }
      if (requiredFields.resellerNameRequired) {
        this.submitProofForm.get('resellerName')?.setValidators([Validators.required]);
        this.submitProofForm.get('resellerName')?.updateValueAndValidity();
        this.resellerNameRequired = true;
      }
    }
  }

  clearFormValidation(): void {
    ['BDFNumber', 'activityDate', 'fileUrl', 'resellerName'].forEach((field) => {
      this.submitProofForm.get(field)?.clearValidators();
      this.submitProofForm.get(field)?.updateValueAndValidity();
    });
    this.BDFNumberRequired = false;
    this.dateRequired = false;
    this.resellerNameRequired = false;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.submitProofForm.valid) {
      const submitProof = this.submitProofForm.value;
      this.activityStore.submitProof(submitProof).then((data) => {
        if (data) {
          // Close current/previous dialog
          this.activeModal.close(true);

          this.dialogService.openFeatureDialog('submitProofThankYouDialog', []);
        }
      });
    }
  }

  onClose(): void {
    this.activeModal.close();
  }

  handleFileUpload(event: any): void {
    this.currentFile = event.target.files[0] as File;
    const sprintActivityId = this.submitProofForm.get('sprintActivityId')?.value;
    this.activityStore.uploadProofFile(sprintActivityId, this.currentFile).then((fileUrl) => {
      this.submitProofForm.get('fileUrl')?.patchValue(fileUrl);
    });
  }
}
