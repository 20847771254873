import { Injectable } from '@angular/core';
import { BasePage, MxLoggerService, SearchResult } from '@motivforce/mx-library-angular';
import { IsLoadingService } from '@service-work/is-loading';
import { BehaviorSubject } from 'rxjs';

import { PromotionRestService } from '../api/rest/promotion-rest.service';
import { PromotionType } from '../enum/promotion-type';
import { Promotion } from '../model/promotion/promotion';
import { PromotionResult } from '../model/promotion/promotion-result';
import { PromotionResultSearch } from '../model/promotion/promotion-result-search';
import { UserStoreService } from './user-store.service';

@Injectable({
  providedIn: 'root',
})
export class PromotionStoreService {
  private readonly _currentPromotionsResults = new BehaviorSubject<SearchResult<Promotion> | null>(null);
  readonly currentPromotionsResults$ = this._currentPromotionsResults.asObservable();

  private readonly _promotions = new BehaviorSubject<Promotion[] | null>(null);
  readonly promotions$ = this._promotions.asObservable();

  private readonly _currentPromotion = new BehaviorSubject<Promotion | null>(null);
  readonly currentPromotion$ = this._currentPromotion.asObservable();

  private readonly _currentQow = new BehaviorSubject<Promotion | null>(null);
  readonly currentQow$ = this._currentQow.asObservable();

  private readonly _currentPow = new BehaviorSubject<Promotion | null>(null);
  readonly currentPow$ = this._currentPow.asObservable();

  private readonly _currentPromotionResultResults = new BehaviorSubject<SearchResult<PromotionResult> | null>(null);
  readonly currentPromotionResultResults$ = this._currentPromotionResultResults.asObservable();

  constructor(
    private promotionRest: PromotionRestService,
    private isLoadingService: IsLoadingService,
    private mxLogger: MxLoggerService,
    private userStore: UserStoreService
  ) {}

  get currentPromotionsResults(): SearchResult<Promotion> | null {
    return this._currentPromotionsResults.getValue();
  }

  get promotions(): Promotion[] | null {
    return this._promotions.getValue();
  }

  get currentPromotion(): Promotion | null {
    return this._currentPromotion.getValue();
  }

  get currentQow(): Promotion | null {
    return this._currentQow.getValue();
  }

  get currentPow(): Promotion | null {
    return this._currentPow.getValue();
  }

  get currentPromotionResultResults(): SearchResult<PromotionResult> | null {
    return this._currentPromotionResultResults.getValue();
  }

  searchPromotions(promotionSearch: BasePage, concatResults = false): void {
    this.isLoadingService.add(
      this.promotionRest.searchPromotions(promotionSearch).subscribe((searchResult) => {
        if (concatResults) {
          this._promotions.next([...this.promotions!, ...searchResult.results]);
        } else {
          this._promotions.next([...searchResult.results]);
        }

        this._currentPromotionsResults.next(searchResult);
      })
    );
  }

  getPromotion(promotionId: number): void {
    this.isLoadingService.add(
      this.promotionRest.getPromotion(promotionId).subscribe((promotion) => {
        this._currentPromotion.next(promotion);
      })
    );
  }

  getAvailableUserQow(): void {
    this.isLoadingService.add(
      this.promotionRest.getAvailableUserPromotions(PromotionType.QOW).subscribe((promotions: Array<any>) => {
        if (promotions && promotions.length > 0) {
          this._currentQow.next(promotions[0]);
        } else {
          this._currentQow.next(null);
        }
      })
    );
  }

  getAvailableUserPow(): void {
    this.isLoadingService.add(
      this.promotionRest.getAvailableUserPromotions(PromotionType.POW).subscribe((promotions: Array<any>) => {
        if (promotions && promotions.length > 0) {
          this._currentPow.next(promotions[0]);
        } else {
          this._currentPow.next(null);
        }
      })
    );
  }

  getActiveQow(): void {
    this.isLoadingService.add(
      this.promotionRest.getActivePromotions(PromotionType.QOW).subscribe((promotions: Array<any>) => {
        if (promotions && promotions.length > 0) {
          this._currentPromotion.next(promotions[0]);
        }
      })
    );
  }

  getActivePow(): void {
    this.isLoadingService.add(
      this.promotionRest.getActivePromotions(PromotionType.POW).subscribe((promotions: Array<any>) => {
        if (promotions && promotions.length > 0) {
          this._currentPromotion.next(promotions[0]);
        }
      })
    );
  }

  finishPromotion(promotionId: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.isLoadingService.add(
        this.promotionRest.finishPromotion(promotionId).subscribe(
          () => {
            resolve(true);
          },
          () => resolve(false)
        )
      );
    });
  }

  searchResults(resultSearch: PromotionResultSearch): void {
    this.isLoadingService.add(
      this.promotionRest.searchResults(resultSearch).subscribe((searchResults) => {
        this._currentPromotionResultResults.next(searchResults);
      })
    );
  }
}
