import { Component, Input } from '@angular/core';
import { GenericCarouselBanner } from '@motivforce/mx-library-angular';

@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.scss'],
})
export class CarouselSliderComponent {
  @Input() banners: GenericCarouselBanner[];
}
