import { Injectable } from '@angular/core';
import { ApiRestService } from '@motivforce/mx-library-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Activity } from '../../model/core/activity';
import { SubmitProof } from '../../model/core/submit-proof';

@Injectable({
  providedIn: 'root',
})
export class ActivityRestService {
  constructor(protected apiRestService: ApiRestService) {}

  getActivities(): Observable<Activity[]> {
    return this.apiRestService.get<Activity[]>(
      `${environment.api.core.baseUrl}/members/clients/nutanix/sprintactivities/activities`
    );
  }

  submitProof(submitProof: SubmitProof): Observable<any> {
    return this.apiRestService.post(
      `${environment.api.core.baseUrl}/members/clients/nutanix/sprintactivities/submissions`,
      submitProof
    );
  }

  getPresignedUrlFile(uploadData: any): Observable<{ fileUrl: string; url: string; fileName: string }> {
    return this.apiRestService.post<{ fileUrl: string; url: string; fileName: string }>(
      `${environment.api.core.baseUrl}/members/clients/nutanix/sprintactivities/submissions/presigned-url`,
      uploadData
    );
  }

  uploadProofFile(file: File, presignedUrl: string): Observable<any> {
    return this.apiRestService.put<any>(presignedUrl, file);
  }
}
