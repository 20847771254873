<app-loading-indicator></app-loading-indicator>
<lib-admin-panel></lib-admin-panel>
<div class="d-flex flex-column h-100">
  <app-header *ngIf="!hideHeaderFooter"></app-header>

  <main role="main" class="flex-fill">
    <!-- [ngClass]="{
    'header-fixed': headerFixed && !hideHeaderFooter,
    'with-navbar': (authenticatedUser$ | async) !== null
  }" -->
    <router-outlet></router-outlet>
    <app-footer class="mt-auto" *ngIf="!hideHeaderFooter"></app-footer>
  </main>
</div>
