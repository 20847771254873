import { Component } from '@angular/core';
import { AbstractDialogComponent } from '@motivforce/mx-library-angular';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivityStoreService } from 'src/app/core/store/activity-store.service';

@Component({
  selector: 'app-submit-proof-thank-you-dialog',
  templateUrl: './submit-proof-thank-you-dialog.component.html',
  styleUrls: ['./submit-proof-thank-you-dialog.component.scss'],
})
export class SubmitProofThankYouDialogComponent extends AbstractDialogComponent {
  defaultMessage = 'Thank you for submitting your proof';

  constructor(public activeModal: NgbActiveModal, public activityStore: ActivityStoreService) {
    super();
  }

  onClose(): void {
    this.activeModal.close();
  }
}
